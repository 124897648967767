import { Icon } from "@chakra-ui/react";
import React from "react";

export function File(props) {
  return (
    <Icon viewBox="0 0 19 21" w="19px" h="21px" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.605493 7.09961H6.60549C7.15778 7.09961 7.60549 6.65189 7.60549 6.09961V0.0996094H17.6075C18.1585 0.0996094 18.6055 0.554609 18.6055 1.09161V19.1076C18.6049 19.6556 18.1605 20.0996 17.6125 20.0996H1.59849C1.33327 20.0978 1.07965 19.9906 0.89343 19.8018C0.707205 19.6129 0.60363 19.3578 0.605493 19.0926V7.09961ZM0.605493 5.09961L5.60549 0.102609V5.09961H0.605493Z"
        fill="currentColor"
      />
    </Icon>
  );
}
