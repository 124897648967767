import { Icon } from "@chakra-ui/react";
import React from "react";

export function Question(props) {
  return (
    <Icon viewBox="0 0 11 11" {...props}>
      <path
        d="M5.5 10.5977C2.7385 10.5977 0.5 8.35916 0.5 5.59766C0.5 2.83616 2.7385 0.597656 5.5 0.597656C8.2615 0.597656 10.5 2.83616 10.5 5.59766C10.5 8.35916 8.2615 10.5977 5.5 10.5977ZM5.5 9.59766C7.70914 9.59766 9.5 7.8068 9.5 5.59766C9.5 3.38852 7.70914 1.59766 5.5 1.59766C3.29086 1.59766 1.5 3.38852 1.5 5.59766C1.5 7.8068 3.29086 9.59766 5.5 9.59766ZM5 7.09766H6V8.09766H5V7.09766ZM6 6.27516V6.59766H5V5.84766C5 5.57151 5.22386 5.34766 5.5 5.34766C5.89508 5.34763 6.22239 5.04113 6.24833 4.64691C6.27428 4.25269 5.98996 3.90593 5.59829 3.85413C5.20663 3.80233 4.84193 4.06324 4.7645 4.45066L3.7835 4.25416C3.95174 3.41328 4.70437 2.81808 5.56139 2.84817C6.41841 2.87825 7.12745 3.52476 7.23632 4.37536C7.34518 5.22597 6.82181 6.0302 6 6.27516Z"
        fill="currentColor"
      />
    </Icon>
  );
}
